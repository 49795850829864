<template>
  <div>
    <a-row class="overflow-hidden auth">
      <a-col :xs="24" :md="24" :lg="12" class="login__wrapper">
        <img src="/logo.svg" alt="Aura-logo" class="login__form--logo" />
        <div class="">
          <router-view v-slot="{ Component }">
            <transition name="route" mode="out-in">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </div>
      </a-col>
      <a-col :xs="24" :md="12" :lg="12" class="login__wrapper--carousel">
        <a-carousel>
          <div class="carousel__img-one">
            <img class="login__form--img" src="/img/aura_idia_cover.png" />
          </div>
        </a-carousel>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import useNotifier from '@/use/notifier'
export default {
  setup() {
    useNotifier()
  }
}
</script>

<style lang="scss" scoped></style>
